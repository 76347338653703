import React from "react";
import homepageimage from '../images/homepage_image.jpg';


const ImageHolder = () => {
    return (
        <div>
            <img src = {homepageimage} style={{ width: '100%', height: '600px', paddingBottom: '80px' }} alt="Home"></img>
        </div>
    )
}

export default ImageHolder;