import React, { useState, useEffect } from "react";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
  Collapse,
} from "@material-tailwind/react";

const HomeNavbar = () => {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 960 && openNav) {
        setOpenNav(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [openNav]);

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {/* Other list items */}
    </ul>
  );

  return (
    <div className="max-h-[768px] w-full overflow-visible">
      <Navbar className="sticky top-0 z-10 max-w-full px-4 py-2 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="#"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
            Golden Sands Investments LLC
          </Typography>
          <div className="hidden lg:flex items-center gap-4">
            {navList}
            <Button variant="text" size="sm">
              Home
            </Button>
            <Button variant="text" size="sm">
              About Us
            </Button>
            <Button variant="text" size="sm">
              Services
            </Button>
            <Button variant="text" size="sm">
              Log In
            </Button>
            <Button variant="gradient" size="sm">
              Sign Up
            </Button>
          </div>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </div>
        <Collapse open={openNav} className="lg:hidden">
          {navList}
          <div className="flex flex-col items-center gap-x-1">
            <Button fullWidth variant="text" size="sm">
              Log In
            </Button>
            <Button fullWidth variant="gradient" size="sm">
              Sign Up
            </Button>
          </div>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default HomeNavbar;
