import './App.css';
import HomeNavbar from './components/homepage/navbar';
import ImageHolder from './components/homepage/imageholder';
import SummaryBlock from './components/homepage/summary';
import HomeFooter from './components/homepage/footer';

function App() {
  return (
    <div className="App">
      <HomeNavbar/>
      <ImageHolder/>
      <SummaryBlock/>
      <HomeFooter/>
    </div>
  );
}

export default App;
