import React from "react";
import skyscrapers from '../images/skyscrapers.png';

const SummaryBlock = () => {
    return (
        <div style = {{paddingBottom: '80px'}}>
            <div className="flex flex-col md:flex-row items-center justify-center bg-white">
                <div className="flex-1" >
                    <img src={skyscrapers} className="w-full h-auto" alt="Skyscrapers"/>
                </div>
                <div className="flex-1 p-4 md:pl-8">
                    <h2 className="text-4xl font-bold mb-4">EXECUTIVE SUMMARY</h2>
                    <p className="text-lg">
                        Golden Sands Investments, LLC is a Real Estate Private Equity Firm that specializes in turning unused 
                        land into High Value Investment Properties which include Residential Villas and Apartments.
                        <br/>
                        <br/>
                        Golden Sands Investments, LLC. is a Real Estate Private Equity Firm that is built on dynamism, strategic 
                        analysis, and forward-thinking principles. Based in Miami, Florida, it was founded by Rupin, a 
                        seasoned industry professional whose upbringing was rooted in real estate. Since the age of 10, he 
                        has been mentored by his father, a successful real estate investor, who imparted a multitude of skills 
                        and experiences acquired in the Asian markets. He primarily dealt with projects that required a value-add strategy, but also engaged in a handful of opportunistic projects. 
                        At Golden Sands Investments, LLC, we have a steadfast commitment to delivering significant returns 
                        for our clients on every project we undertake. We are unwavering in our willingness to take all 
                        reasonable measures to ensure the achievement of our objectives. Simultaneously, we place great 
                        emphasis on risk mitigation, particularly when entrusted with our client's portfolios. To uphold our 
                        financial commitment while minimizing risk Rupin will diligently manage day-to-day operations to 
                        ensure a seamless and consistently high level of performance.
                        We firmly believe that our clients should have a voice in deciding how their money is spent. Thus, we 
                        empower our clients to shape our business decisions by considering their input through their votes on 
                        the projects to pursue.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default SummaryBlock;
